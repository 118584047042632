import { domReady, meta, qs, qsa, removeAttribute, scrollIntoView, setAttribute } from 'lib/dom.js'
import { dispatch, off, on, stop } from 'lib/events.js'
import { prefersReducedMotion } from 'lib/transition.js'

function abandonComponentHandler({ target }) {
	return target.nodeName !== 'FORM' || !target.classList.contains('form-component')
}

export function initializeSteps(form, force = false) {
	if (form.dataset.hasSteps === undefined || force) {
		const formSubmitContainer = qs('.form-submit-container', form)
		const steps = qsa('fieldset[data-step]', form)

		form.dataset.hasSteps = steps.length > 0

		/*
		 * Set up the steps and their visibility.
		 */
		removeAttribute(formSubmitContainer, 'hidden')

		steps.forEach((step, index) => {
			step.classList.toggle('active', index === 0)

			if (index === 0) {
				form.currentStep = step
				removeAttribute(step, 'hidden')

				if (qs('button[type="submit"]', step)) {
					setAttribute(formSubmitContainer, 'hidden', '')
				}

				setTitleFromStepToStep(null, step)
			} else {
				setAttribute(step, 'hidden', '')
			}
		})
	}

	return form
}

function setPageTitle(title) {
	let suffix

	const seperator = ' - '
	const indexOfSplit = (document.title || '').lastIndexOf(seperator)

	/*
	 * Some titles contain HTML which we need to strip out before injecting into
	 * the <title>
	 */
	const div = document.createElement('div')
	div.innerHTML = title
	const titleText = div.textContent

	if (indexOfSplit > -1) {
		suffix = document.title.slice(indexOfSplit + seperator.length)
	}

	document.title = [titleText, suffix].filter(Boolean).join(seperator)
	qs('.titlebar-component h1').innerHTML = title
}

function setTitleFromStepToStep(fromStep, toStep) {
	let previousTitle

	const form = toStep.form
	const steps = qsa('fieldset[data-step]', form)

	if (document.head.dataset.originalTitle === undefined) {
		let title = document.title
		const seperator = ' - '
		const indexOfSplit = (title || '').lastIndexOf(seperator)

		if (indexOfSplit > -1) {
			title = title.slice(0, indexOfSplit)
		}

		document.head.dataset.originalTitle = title
	}

	/*
	 * In the second scenario, the form is transitioning backward through the
	 * steps so we look back through the steps and either set the title to a
	 * previous step's title or the document's original title.
	 */
	if (toStep.dataset.title !== undefined) {
		setPageTitle(toStep.dataset.title)
	} else if (fromStep === steps[steps.indexOf(toStep) + 1]) {
		let stepIndex = steps.indexOf(toStep) - 1

		while(stepIndex >= 0 && previousTitle === undefined) {
			if (steps[stepIndex] !== undefined) {
				if (steps[stepIndex].dataset.title !== undefined) {
					previousTitle = steps[stepIndex].dataset.title
				}
			}

			stepIndex--
		}

		setPageTitle(previousTitle || document.head.dataset.originalTitle)
	}
}

export function transitionFromStepToStep(fromStep, toStep) {
	setTitleFromStepToStep(fromStep, toStep)

	const form = fromStep.form
	const formSubmitButtonContainer = qs('.form-submit-container', form)

	if (prefersReducedMotion) {
		fromStep.classList.remove('active')
		setAttribute(fromStep, 'hidden', '')
		dispatch(form, 'stepTransitionFromStepEnd', { detail: { step: fromStep } })

		// toStep has its own submit button so we hide the main form's.
		if (qs('button[type="submit"]', toStep)) {
			setAttribute(formSubmitButtonContainer, 'hidden', '')
		} else {
			removeAttribute(formSubmitButtonContainer, 'hidden')
		}

		removeAttribute(toStep, 'hidden')
		toStep.classList.add('active')
		form.currentStep = toStep
		dispatch(form, 'stepTransitionToStepEnd', { detail: { step: toStep } })
		dispatch(toStep, 'input') // Trigger our submit button state.

		/* Attempt to focus the first element in the next step, except buttons. */
		const firstFormElement = toStep.elements.item(0)

		if (firstFormElement && !firstFormElement.matches('button')) {
			firstFormElement.focus()
		}

		scrollIntoView(form)
		dispatch(form, 'stepTransitionEnd')
	} else {
		const toStepTransitionHandler = (previousTransitionEvent) => {
			if (previousTransitionEvent.propertyName === 'opacity') {
				off(toStep, 'transitionend', toStepTransitionHandler)
				dispatch(form, 'stepTransitionToStepEnd', { detail: { step: toStep } })
				dispatch(toStep, 'input') // Trigger our submit button state.

				/* Attempt to focus the first element in the next step, except buttons. */
				const firstFormElement = toStep.elements.item(0)

				if (firstFormElement && !firstFormElement.matches('button')) {
					firstFormElement.focus()
				}

				scrollIntoView(form)
				dispatch(form, 'stepTransitionEnd')
			}
		}

		const fromStepTransitionHandler = (transitionEvent) => {
			if (transitionEvent.propertyName === 'opacity') {
				off(fromStep, 'transitionend', fromStepTransitionHandler)
				setAttribute(fromStep, 'hidden', '')
				dispatch(form, 'stepTransitionFromStepEnd', { detail: { step: fromStep } })

				// toStep has its own submit button so we hide the main form's.
				if (qs('button[type="submit"]', toStep)) {
					setAttribute(formSubmitButtonContainer, 'hidden', '')
				} else {
					removeAttribute(formSubmitButtonContainer, 'hidden')
				}

				removeAttribute(toStep, 'hidden')
				toStep.offsetHeight /* Forces a browser reflow so our animation will work. */

				on(toStep, 'transitionend', toStepTransitionHandler, { passive: true })
				toStep.classList.add('active')
				form.currentStep = toStep
			}
		}

		on(fromStep, 'transitionend', fromStepTransitionHandler, { passive: true })
		fromStep.classList.remove('active')
	}
}

function onStepToStep(stepEvent) {
	if (abandonComponentHandler(stepEvent)) {
		return
	}

	const form = initializeSteps(stepEvent.target)
	const currentStep = form.currentStep
	const nextStep = stepEvent.detail

	if (nextStep) {
		transitionFromStepToStep(currentStep, nextStep)
	}
}

function onStepBackward(stepBackwardEvent) {
	if (abandonComponentHandler(stepBackwardEvent)) {
		return
	}

	const form = initializeSteps(stepBackwardEvent.target)
	const currentStep = form.currentStep
	const previousStep = currentStep.previousElementSibling

	if (previousStep !== null && previousStep.matches('fieldset[data-step]')) {
		transitionFromStepToStep(currentStep, previousStep)
	}
}

function onStepForward(stepForwardEvent) {
	if (abandonComponentHandler(stepForwardEvent)) {
		return
	}

	const form = initializeSteps(stepForwardEvent.target)
	const currentStep = form.currentStep
	const nextStep = currentStep.nextElementSibling

	if (nextStep !== null && nextStep.matches('fieldset[data-step]')) {
		transitionFromStepToStep(currentStep, nextStep)
	}
}

function onSubmit(submitEvent) {
	if (abandonComponentHandler(submitEvent)) {
		return
	}

	const form = initializeSteps(submitEvent.target)

	if (form.dataset.hasSteps === 'true') {
		const currentStep = form.currentStep
		const currentStepElements = Array.from(currentStep.elements)

		if (currentStepElements.every(elm => elm.validity.valid)) {
			const nextStep = currentStep.nextElementSibling

			if (nextStep !== null && nextStep.matches('fieldset[data-step]')) {
				stop(submitEvent)

				dispatch(form, 'stepForward')
			}
		} else {
			stop(submitEvent)

			currentStep.classList.add('was-validated')

			qsa('.input-component, .select-component', currentStep).forEach(comp => {
				qsa('input, select', comp).forEach(componentInput => {
					dispatch(componentInput, 'validate', { detail: { [submitEvent.type]: submitEvent }})
				})
			})

			/*
			 * Grab the first invalid element, validate it so the styles get updated
			 * appropriately, and focus it.
			 */
			const firstInvalidElement = currentStepElements.find(elm => !elm.validity.valid)
			dispatch(firstInvalidElement, 'validate')
			firstInvalidElement.focus()
		}
	} else if (form.checkValidity() === false) {
		form.classList.add('was-validated')

		qsa('.input-component, .select-component', form).forEach(comp => {
			qsa('input, select', comp).forEach(componentInput => {
				dispatch(componentInput, 'validate', { detail: { [submitEvent.type]: submitEvent }})
			})
		})

		stop(submitEvent)
	}
}

function onSubmitConfirm(submitEvent) {
	if (abandonComponentHandler(submitEvent)) {
		return
	}

	const form = initializeSteps(submitEvent.target)

	if (form.dataset.confirm) {
		if (!confirm(form.dataset.confirm)) {
			stop(submitEvent)
		}
	}
}

function onSubmitWorking(submitEvent) {
	if (abandonComponentHandler(submitEvent)) {
		return
	}

	dispatch(initializeSteps(submitEvent.target), 'working')
}

function onWorking(workingEvent) {
	if (abandonComponentHandler(workingEvent)) {
		return
	}

	const form = initializeSteps(workingEvent.target)
	const working = meta('forms.working')
	let submitButton = null

	if (form.dataset.hasSteps === 'true') {
		submitButton = qs('button[type=submit]', form.currentStep)
	}

	/*
	 * This doesn't use an if-else because the above assignment may not actually
	 * find anything in the case that the last step in a form does not have its
	 * own submit button.
	 */
	if (!submitButton) {
		submitButton = qs('.form-submit-container button[type=submit]', form)
	}

	submitButton.dataset.originalText = submitButton.textContent
	setAttribute(submitButton, 'disabled', '')
	submitButton.classList.add('disabled')
	submitButton.textContent = working
}

function onRevert(revertEvent) {
	if (abandonComponentHandler(revertEvent)) {
		return
	}

	const form = initializeSteps(revertEvent.target, true)
	let submitButton = null

	if (form.dataset.hasSteps === 'true') {
		submitButton = qs('button[type=submit]', form.currentStep)
	}

	submitButton = submitButton || qsa('button[type=submit]', form).pop()

	removeAttribute(submitButton, 'disabled')
	submitButton.classList.remove('disabled')

	if (submitButton.dataset.originalText) {
		submitButton.textContent = submitButton.dataset.originalText
	}
}

function updateValidationClasses(event) {
	if (!abandonComponentHandler(event) || (event.target.form && !abandonComponentHandler({ target: event.target.form }))) {
		const form = initializeSteps(!abandonComponentHandler(event) ? event.target : event.target.form)

		form.classList.toggle('invalid', !form.checkValidity())

		if (form.dataset.hasSteps === 'true') {
			const currentStep = form.currentStep
			const currentStepElements = Array.from(currentStep.elements)

			currentStep.classList.toggle('invalid', !currentStepElements.every(elm => elm.validity.valid))
		}
	}
}

function validateFormElements(event) {
	if (abandonComponentHandler(event)) {
		return
	}

	const form = initializeSteps(event.target)
	const elements = Array.from(form.dataset.hasSteps === 'true' ? form.currentStep.elements : form.elements)

	elements.forEach(elm => dispatch(elm, event.type))
}

// Submit and revert
on(document, 'submit', onSubmit)
on(document, 'submit', onSubmitConfirm)
on(document, 'submit', onSubmitWorking, { passive: true })
on(document, 'revert', onRevert, { passive: true })
on(document, 'working', onWorking, { passive: true })

// Steps
on(document, 'stepForward', onStepForward, { passive: true })
on(document, 'stepBackward', onStepBackward, { passive: true })
on(document, 'stepToStep', onStepToStep, { passive: true })

// Validity classes e.g. invalid.
on(document, 'change, input, stepTransitionEnd', updateValidationClasses, { passive: true })

/*
 * Validate form elements early on. Note that this event handler is removed
 * after a certain amount of time since this is designed to catch autofill
 * events.
 */
on(document, 'input', validateFormElements, { passive: true })

domReady(_ => {
	const forms = qsa('form.form-component')

	forms.forEach(form => {
		dispatch(form, 'input')
	})

	setTimeout(_ => off(document, 'input', validateFormElements, { passive: true }), 550)
})
